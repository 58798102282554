// vendor
import 'bootstrap';
import 'parsleyjs';
import 'slick-carousel/slick/slick';
import 'smartbanner.js/dist/smartbanner.min';
import toastr from 'toastr';
window.toastr = toastr;

import 'select2/dist/js/select2.full';
$.fn.select2.defaults.set('theme', 'bootstrap');

// components
import './components/menu';
import './components/main';
import './components/homepage';
import './components/header';
import './components/main-banner';
import './components/main-banner-timer';
import './components/parsley-validators';
import './components/contact-us-form';
import './components/go-to-top-btn';
import './components/related-worksheets';
import './components/related-articles';
import './components/my-messages';
import './components/website-limit-modal';
import './components/shipping-addon-form';
import './worksheet/worksheet-list';
import './components/collection-list';
import './components/website-quesstion-modal';
import './payment/subscription-plans-sample';
import './components/website-plans-modal';
import './components/website-cr-tg-plans-modal';
import './components/website-sign-up-modal';

// classroom
import './classroom/classroom-join';
import './classroom/classroom-password-reset';
import './classroom/classroom';
